import React, {useEffect, useContext} from 'react';
import AppContext from '../context/app/appContext';
import Loading from './Loading';
import Text from './Text';

const ActivatedUsers = () => {
    let total = [];
    const appContext = useContext(AppContext);
    const { getActivatedUsers, activatedUsers, token, loading } = appContext;

    useEffect(() => {
        const timer = setTimeout(() => {
            getActivatedUsers(token);
        }, 1000);

        const interval = setInterval(() => {
            const timer = setTimeout(() => {
                getActivatedUsers(token);
                console.log('getActivatedUsers every 5min'); 
            }, 500);
            return () => clearTimeout(timer);
        }, 300 * 1000);
          return () => {
            clearTimeout(timer);
            clearInterval(interval);
          }
    }, [token])

    if(!activatedUsers || activatedUsers.length === 0) {
        return null;
    }

    activatedUsers.data.map((item) => total.push(item.value));
    
    const totalReduced = total.reduce((a, b) => a + b);
    
    const totalFormatted = new Intl.NumberFormat('sv-SE', {
        style: 'decimal',
        maximumFractionDigits: 0,
      }).format(totalReduced);

    return (
        <div className="d-inline text-color-main">{totalFormatted}</div>
    )
}

export default ActivatedUsers;
