import React, {Fragment, useEffect, useState} from 'react';
import BrandIcon from '../assets/images/koppla_k_124x150_v2.gif';
import ActivatedUsers from '../components/activatedUsers';

const Footer = () => {
    return (
        <Fragment>
            <footer className="footer row d-lg-flex align-items-lg-center justify-content-lg-between">
                <div className="col-12 col-lg-4 footer--onboarded-users">
                      <span className="text-uppercase">Totalt antal onboardade cyklar: <ActivatedUsers/></span><br />
                      <span className="text-normal">(Koppla Light & Koppla Connect)</span>
                </div>
                <div className="col-12 col-lg-4 footer--tagline text-uppercase text-center mb-3 mb-lg-0">
                    Uppdateras var femte minut
                </div>
                <div className="col-12 col-lg-4">
                    <figure className="footer--brand-icon text-center text-lg-right">
                        <img src={BrandIcon} alt="Ladda Dashboard" />
                    </figure>
                </div>
            </footer>
        </Fragment>
    )
}
export default Footer;
