import React, { useReducer } from "react";
import axios from "axios";
import AppContext from "./appContext";
import AppReducer from "./appReducer";
import {
  AUTHENTICATING,
  GET_RIDES_TOTAL,
  GET_RIDES_DISTANCE,
  GET_RIDES_TODAY,
  GET_RIDES_BEST_DAY_OF_WEEK,
  GET_RIDES_TRAVELED,
  GET_TOTAL_MINUTES_OF_RIDES,
  SET_LOADING,
  GET_ACTIVATED_USERS,
} from "../types";

const AppState = (props) => {
  const initialState = {
    isAuthenticated: false,
    token: null,
    loading: false,
    ridesToday: null,
    ridesTotal: null,
    ridesDistance: null,
    ridesBestDayOfWeek: null,
    ridesTraveled: null,
    activatedUsers: null,
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);

  const authenticating = async () => {
    const reqOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: "62f7e2a1-c356-4937-abad-c3c1546b217c",
        client_secret:
          "mQJEYQmtoL6xkk{&pB3FcyZFqAcgJn4rDU8fwzGdA29LYJHzbLaxdFvquZeAK#vf",
        grant_type: "client_credentials",
      }),
    };

    const response = await fetch(
      "https://crescent.api.bike.conneq.tech/oauth",
      reqOptions
    );
    const data = await response.json();

    dispatch({
      type: AUTHENTICATING,
      payload: data.access_token,
    });
  };

  //Get rides today
  const getRidesToday = async (token) => {
    setLoading();
    state.ridesToday = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/rides/day`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: today,
        end_date: today,
      },
    });

    dispatch({
      type: GET_RIDES_TODAY,
      payload: res.data.data[0],
    });
  };

  //Get rides total
  const getRidesTotal = async (token) => {
    setLoading();
    state.ridesPerDay = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/rides/total`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: "2020-01-01",
        end_date: today,
      },
    });

    dispatch({
      type: GET_RIDES_TOTAL,
      payload: res.data,
    });
  };

  //Get rides distance
  const getRidesDistance = async (token) => {
    setLoading();
    state.ridesDistance = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/rides/distance`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: "2020-01-01",
        end_date: today,
      },
    });

    dispatch({
      type: GET_RIDES_DISTANCE,
      payload: res.data,
    });
  };

  //Get best day of week
  const getRidesBestDayOfWeek = async (token) => {
    setLoading();
    state.ridesBestDayOfWeek = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/rides/day`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: "2020-01-01",
        end_date: today,
      },
    });

    dispatch({
      type: GET_RIDES_BEST_DAY_OF_WEEK,
      payload: res.data,
    });
  };

  //Get rides travled
  const getRidesTraveled = async (token) => {
    setLoading();
    state.ridesTraveled = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/rides/distributed/traveled`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: "2020-01-01",
        end_date: today,
      },
    });

    dispatch({
      type: GET_RIDES_TRAVELED,
      payload: res.data,
    });
  };

  //Get total minutes of rides
  const getTotalMinutesOfRides = async (token) => {
    setLoading();
    state.totalMinutesOfRides = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/rides/weather/duration`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: "2020-01-01",
        end_date: today,
      },
    });

    dispatch({
      type: GET_TOTAL_MINUTES_OF_RIDES,
      payload: res.data,
    });
  };

  //Get activated users
  const getActivatedUsers = async (token) => {
    setLoading();
    state.activatedUsers = [];
    const today = formatDate(new Date());

    const url = `https://crescent.api.general-stats.conneq.tech/crescent/v1/bike/new`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        start_date: "2020-01-01",
        end_date: today,
      },
    });

    //console.log(res.data);

    dispatch({
      type: GET_ACTIVATED_USERS,
      payload: res.data,
    });
  };

  //Set loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  return (
    <AppContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        token: state.token,
        loading: state.loading,
        ridesTotal: state.ridesTotal,
        ridesDistance: state.ridesDistance,
        ridesToday: state.ridesToday,
        ridesBestDayOfWeek: state.ridesBestDayOfWeek,
        ridesTraveled: state.ridesTraveled,
        totalMinutesOfRides: state.totalMinutesOfRides,
        activatedUsers: state.activatedUsers,
        authenticating,
        getRidesTotal,
        getRidesDistance,
        getRidesToday,
        getRidesBestDayOfWeek,
        getRidesTraveled,
        getTotalMinutesOfRides,
        getActivatedUsers,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
